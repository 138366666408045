import { defineMessages } from 'react-intl';

export default defineMessages({
  skip: {
    id: 'app.common.skip',
    defaultMessage: 'Skip',
  },
  add: {
    id: 'app.common.add',
    defaultMessage: 'Add',
  },
  remove: {
    id: 'app.common.remove',
    defaultMessage: 'Remove',
  },
  attachment: {
    id: 'app.common.attachment',
    defaultMessage: 'Attachment',
  },
  attachments: {
    id: 'app.common.attachments',
    defaultMessage: 'Attachments',
  },
  change: {
    id: 'app.common.change',
    defaultMessage: 'Change',
  },
  back: {
    id: 'app.common.back',
    defaultMessage: 'Back',
  },
  save: {
    id: 'app.common.save',
    defaultMessage: 'Save',
  },
  calendar: {
    id: 'app.common.calendar',
    defaultMessage: 'Calendar',
  },
  quickPrint: {
    id: 'app.common.quickPrint',
    defaultMessage: 'Quick print',
  },
  notifications: {
    id: 'app.common.notifications',
    defaultMessage: 'Notifications',
  },
  everyDay: {
    id: 'app.common.everyDay',
    defaultMessage: 'Every day',
  },
  toDo: {
    id: 'app.common.toDo',
    defaultMessage: 'To do',
  },
  everyWeek: {
    id: 'app.common.everyWeek',
    defaultMessage: 'Every week',
  },
  everyMonth: {
    id: 'app.common.everyMonth',
    defaultMessage: 'Every month',
  },
  everyNDay: {
    id: 'app.common.everyNDay',
    defaultMessage: 'Every N day',
  },
  everyNWeek: {
    id: 'app.common.everyNWeek',
    defaultMessage: 'Every N week',
  },
  everyYear: {
    id: 'app.common.everyYear',
    defaultMessage: 'Every year',
  },
  everyNWeekDay: {
    id: 'app.common.everyNWeekDay',
    defaultMessage: 'Every N week day',
  },
  cancel: {
    id: 'app.common.cancel',
    defaultMessage: 'Cancel',
  },
  general: {
    id: 'app.common.general',
    defaultMessage: 'General',
  },
  generate: {
    id: 'app.common.generate',
    defaultMessage: 'Generate',
  },
  checking: {
    id: 'app.common.checking',
    defaultMessage: 'Checking {thing}...',
  },
  customer: {
    id: 'app.common.customer',
    defaultMessage: 'Customer',
  },
  delete: {
    id: 'app.common.delete',
    defaultMessage: 'Delete',
  },
  deletion: {
    id: 'app.common.deletion',
    defaultMessage: 'Deletion',
  },
  startDate: {
    id: 'app.common.startDate',
    defaultMessage: 'Start date',
  },
  endDate: {
    id: 'app.common.endDate',
    defaultMessage: 'End date',
  },
  employee: {
    id: 'app.common.employee',
    defaultMessage: 'Employee',
  },
  employees: {
    id: 'app.common.employees',
    defaultMessage: 'Employees',
  },
  employeesCount: {
    id: 'app.common.employeesCount',
    defaultMessage: '{count, plural, =0 {no employees} one {# employee} other {# employees}}',
  },
  group: {
    id: 'app.common.group',
    defaultMessage: 'Group',
  },
  groups: {
    id: 'app.common.groups',
    defaultMessage: 'Groups',
  },
  title: {
    id: 'app.common.title',
    defaultMessage: 'Title',
  },
  create: {
    id: 'app.common.create',
    defaultMessage: 'Create',
  },
  collapse: {
    id: 'app.common.collapse',
    defaultMessage: 'Collapse',
  },
  description: {
    id: 'app.common.description',
    defaultMessage: 'Description',
  },
  address: {
    id: 'app.common.address',
    defaultMessage: 'Address',
  },
  phone: {
    id: 'app.common.phone',
    defaultMessage: 'Phone',
  },
  email: {
    id: 'app.common.email',
    defaultMessage: 'Email',
  },
  duration: {
    id: 'app.common.duration',
    defaultMessage: 'Duration',
  },
  startTime: {
    id: 'app.common.startTime',
    defaultMessage: 'Start time',
  },
  endTime: {
    id: 'app.common.endTime',
    defaultMessage: 'End time',
  },
  name: {
    id: 'app.common.name',
    defaultMessage: 'Name',
  },
  yes: { id: 'app.common.yes', defaultMessage: 'Yes' },
  no: { id: 'app.common.no', defaultMessage: 'No' },
  showAll: {
    id: 'app.common.showAll',
    defaultMessage: 'Show all',
  },
  print: {
    id: 'app.common.print',
    defaultMessage: 'Print',
  },
  startDateAndTime: {
    id: 'app.common.startDateAndTime',
    defaultMessage: 'Start date and time',
  },
  endDateAndTime: {
    id: 'app.common.endDateAndTime',
    defaultMessage: 'End date and time',
  },
  dateInPast: {
    id: 'app.common.dateInPast',
    defaultMessage: 'Date cannot be in the past',
  },
  endTimeBeforeStart: {
    id: 'app.common.endTimeBeforeStart',
    defaultMessage: "End time can't be before start time",
  },
  edit: {
    id: 'app.common.edit',
    defaultMessage: 'Edit',
  },
  update: {
    id: 'app.common.update',
    defaultMessage: 'Update',
  },
  download: {
    id: 'app.common.download',
    defaultMessage: 'Download',
  },
  dropZone: {
    id: 'app.common.dropZone',
    defaultMessage: 'Drop file(s) here to upload them',
  },
  date: {
    id: 'app.common.date',
    defaultMessage: 'Date',
  },
  filters: {
    id: 'app.common.filters',
    defaultMessage: 'Filters',
  },
  header: {
    id: 'app.common.header',
    defaultMessage: 'Header',
  },
  today: {
    id: 'app.common.today',
    defaultMessage: 'Today',
  },
  repetition: {
    id: 'app.common.repetition',
    defaultMessage: 'Repetition',
  },
  actions: {
    id: 'app.common.actions',
    defaultMessage: 'Actions',
  },
  action: {
    id: 'app.common.action',
    defaultMessage: 'Action',
  },
  close: {
    id: 'app.common.close',
    defaultMessage: 'Close',
  },
  status: {
    id: 'app.common.status',
    defaultMessage: 'Status',
  },
  start: {
    id: 'app.common.start',
    defaultMessage: 'Start',
  },
  end: {
    id: 'app.common.end',
    defaultMessage: 'End',
  },
  noRows: {
    id: 'app.common.noRows',
    defaultMessage: 'No rows',
  },
  notAvailable: {
    id: 'app.common.notAvailable',
    defaultMessage: 'Not available',
  },
  available: {
    id: 'app.common.available',
    defaultMessage: 'Available',
  },
  required: {
    id: 'app.common.required',
    defaultMessage: 'Required',
  },
  requirements: {
    id: 'app.common.requirements',
    defaultMessage: 'Requirements',
  },
  isNotNumber: {
    id: 'app.common.isNotNumber',
    defaultMessage: 'Is not a number',
  },
  shouldBePositive: {
    id: 'app.common.shouldBePositive',
    defaultMessage: 'Should be positive',
  },
  shouldBeNegative: {
    id: 'app.common.shouldBeNegative',
    defaultMessage: 'Should be negative',
  },
  valueOk: {
    id: 'app.common.valueOk',
    defaultMessage: 'Value ok',
  },
  requiredField: {
    id: 'app.common.requiredField',
    defaultMessage: 'This field is required',
  },
  day: {
    id: 'app.common.day',
    defaultMessage: 'day',
  },
  dayShort: {
    id: 'app.common.dayShort',
    defaultMessage: 'd',
  },
  days: {
    id: 'app.common.days',
    defaultMessage: 'days',
  },
  daysPlural: {
    id: 'app.common.daysPlural',
    defaultMessage: '{days, plural, =0 {# days} one {# day} other {# days}}',
  },
  hoursPlural: {
    id: 'app.common.hoursPlural',
    defaultMessage: '{hours, plural, =0 {# hours} one {# hour} other {# hours}}',
  },
  minutesPlural: {
    id: 'app.common.minutesPlural',
    defaultMessage: '{minutes, plural, =0 {# minutes} one {# minute} other {# minutes}}',
  },
  hour: {
    id: 'app.common.hour',
    defaultMessage: 'hour',
  },
  hourShort: {
    id: 'app.common.hourShort',
    defaultMessage: 'h',
  },
  cancelTask: {
    id: 'app.common.cancelTask',
    defaultMessage: 'Cancel task',
  },
  continueTask: {
    id: 'app.common.continueTask',
    defaultMessage: 'Continue task',
  },
  hours: {
    id: 'app.common.hours',
    defaultMessage: 'hours',
  },
  minutes: {
    id: 'app.common.minutes',
    defaultMessage: 'minutes',
  },
  minuteShort: {
    id: 'app.common.minuteShort',
    defaultMessage: 'm',
  },
  month: {
    id: 'app.common.month',
    defaultMessage: 'Month',
  },
  week: {
    id: 'app.common.week',
    defaultMessage: 'Week',
  },
  invalidInput: {
    id: 'app.common.invalidInput',
    defaultMessage: 'Invalid input',
  },
  list: {
    id: 'app.common.list',
    defaultMessage: 'List',
  },
  listDay: {
    id: 'app.common.listDay',
    defaultMessage: 'List day',
  },
  streetAddress: {
    id: 'app.common.streetAddress',
    defaultMessage: 'Street address',
  },
  postalCode: {
    id: 'app.common.postalCode',
    defaultMessage: 'Postal code',
  },
  postalArea: {
    id: 'app.common.postalArea',
    defaultMessage: 'Postal area',
  },
  team: {
    id: 'app.common.team',
    defaultMessage: 'Team',
  },
  teams: {
    id: 'app.common.teams',
    defaultMessage: 'Teams',
  },
  unit: {
    id: 'app.common.unit',
    defaultMessage: 'Unit',
  },
  units: {
    id: 'app.common.units',
    defaultMessage: 'Units',
  },
  reminder: {
    id: 'app.common.reminder',
    defaultMessage: 'Reminder',
  },
  visibility: {
    id: 'app.common.visibility',
    defaultMessage: 'Visibility',
  },
  type: {
    id: 'app.common.type',
    defaultMessage: 'Type',
  },
  select: {
    id: 'app.common.select',
    defaultMessage: 'Select',
  },
  selected: {
    id: 'app.common.selected',
    defaultMessage: 'Selected',
  },
  hide: {
    id: 'app.common.hide',
    defaultMessage: 'Hide',
  },
  show: {
    id: 'app.common.show',
    defaultMessage: 'Show',
  },
  selectAll: {
    id: 'app.common.selectAll',
    defaultMessage: 'Select all',
  },
  postOffice: {
    id: 'app.common.postOffice',
    defaultMessage: 'Post office',
  },
  taskCount: {
    id: 'app.common.taskCount',
    defaultMessage: '{taskCount, plural, =0 {No task} one {# task} other {# tasks}}',
  },
  tasks: {
    id: 'app.common.tasks',
    defaultMessage: 'Tasks',
  },
  seconds: {
    id: 'app.common.seconds',
    defaultMessage: '{second, plural, =0 {} one {# second} other {# seconds}}',
  },
  ssnAbbreviation: {
    id: 'app.common.ssnAbbreviation',
    defaultMessage: 'SSN',
  },
  ssnFull: {
    id: 'app.common.ssnFull',
    defaultMessage: 'Social security number',
  },
  sortBy: {
    id: 'app.common.sortBy',
    defaultMessage: 'Sort by',
  },
  id: {
    id: 'app.common.id',
    defaultMessage: 'ID',
  },
  search: {
    id: 'app.common.search',
    defaultMessage: 'Search',
  },
  searchHeader: {
    id: 'app.common.searchHeader',
    defaultMessage: 'Search',
  },
  searchTerm: {
    id: 'app.common.searchTerm',
    defaultMessage: 'Search term',
  },
  all: {
    id: 'app.common.all',
    defaultMessage: 'All',
  },
  carePlan: {
    id: 'app.common.carePlan',
    defaultMessage: 'CarePlan',
  },
  done: {
    id: 'app.common.done',
    defaultMessage: 'Done',
  },
  notDone: {
    id: 'app.common.notDone',
    defaultMessage: 'Not done',
  },
  public: {
    id: 'app.common.public',
    defaultMessage: 'Public',
  },
  private: {
    id: 'app.common.private',
    defaultMessage: 'Private',
  },
  both: {
    id: 'app.common.both',
    defaultMessage: 'Both',
  },
  clear: {
    id: 'app.common.clear',
    defaultMessage: 'Clear',
  },
  confirm: {
    id: 'app.common.confirm',
    defaultMessage: 'Confirm',
  },
  confirmed: {
    id: 'app.common.confirmed',
    defaultMessage: 'Confirmed',
  },
  unconfirmed: {
    id: 'app.common.unconfirmed',
    defaultMessage: 'Unconfirmed',
  },
  somethingWentWrong: {
    id: 'app.common.somethingWentWrong',
    defaultMessage: 'Oops! Something went wrong!',
  },
  workShift: {
    id: 'app.common.workShift',
    defaultMessage: 'Work shift',
  },
  finished: {
    id: 'app.common.finished',
    defaultMessage: 'Finished',
  },
  auctioned: {
    id: 'app.common.auctioned',
    defaultMessage: 'Auctioned',
  },
  walk: {
    id: 'app.common.walk',
    defaultMessage: 'Walk',
  },
  car: {
    id: 'app.common.car',
    defaultMessage: 'Car',
  },
  bicycle: {
    id: 'app.common.bicycle',
    defaultMessage: 'Bicycle',
  },
  lastName: {
    id: 'app.common.lastName',
    defaultMessage: 'Last name',
  },
  firstName: {
    id: 'app.common.firstName',
    defaultMessage: 'First name',
  },
  notes: {
    id: 'app.common.notes',
    defaultMessage: 'Notes',
  },
  time: {
    id: 'app.common.time',
    defaultMessage: 'Time',
  },
  default: {
    id: 'app.common.default',
    defaultMessage: 'Default',
  },
  help: {
    id: 'app.common.help',
    defaultMessage: 'Help',
  },
  defaults: {
    id: 'app.common.defaults',
    defaultMessage: 'Defaults',
  },
  emptyRecord: {
    id: 'app.common.emptyRecord',
    defaultMessage: 'No records found',
  },
  accept: {
    id: 'app.common.accept',
    defaultMessage: 'Accept',
  },
  plannedTime: {
    id: 'app.common.plannedTime',
    defaultMessage: 'Planned time',
  },
  plannedDate: {
    id: 'app.common.plannedDate',
    defaultMessage: 'Planned date',
  },
  plannedDuration: {
    id: 'app.common.plannedDuration',
    defaultMessage: 'Planned duration',
  },
  realizedDate: {
    id: 'app.common.realizedDate',
    defaultMessage: 'Realized date',
  },
  realizedTime: {
    id: 'app.common.realizedTime',
    defaultMessage: 'Realized time',
  },
  realizedDuration: {
    id: 'app.common.realizedDuration',
    defaultMessage: 'Realized duration',
  },
  realized: {
    id: 'app.common.realized',
    defaultMessage: 'Realized',
  },
  draft: {
    id: 'app.common.draft',
    defaultMessage: 'Draft',
  },
  planned: {
    id: 'app.common.planned',
    defaultMessage: 'Planned',
  },
  customerAddress: {
    id: 'app.common.customerAddress',
    defaultMessage: 'Customer address',
  },
  additionalInfo: {
    id: 'app.common.additionalInfo',
    defaultMessage: 'Additional info',
  },
  otherConsiderations: {
    id: 'app.common.otherConsiderations',
    defaultMessage: 'Other considerations',
  },
  groupId: {
    id: 'app.common.groupId',
    defaultMessage: 'Group id',
  },
  city: {
    id: 'app.common.city',
    defaultMessage: 'City',
  },
  warning: {
    id: 'app.common.warning',
    defaultMessage: 'Warning',
  },
  task: {
    id: 'app.common.task',
    defaultMessage: 'Task',
  },
  website: {
    id: 'app.common.website',
    defaultMessage: 'Website',
  },
  unsavedChanges: {
    id: 'app.common.unsavedChanges',
    defaultMessage: 'Unsaved changes',
  },
  unsavedChangesBody: {
    id: 'app.common.unsavedChangesBody',
    defaultMessage: 'There are unsaved changes, are you sure you want to cancel?',
  },
  details: {
    id: 'app.common.details',
    defaultMessage: 'Details',
  },
  basicInfo: {
    id: 'app.common.basicInfo',
    defaultMessage: 'Basic info',
  },
  billingInfo: {
    id: 'app.common.billingInfo',
    defaultMessage: 'Billing info',
  },
  ratio: {
    id: 'app.common.ratio',
    defaultMessage: 'Ratio',
  },
  specifier: {
    id: 'app.common.specifier',
    defaultMessage: 'Specifier',
  },
  comments: {
    id: 'app.common.comments',
    defaultMessage: 'Comments',
  },
  socialCare: {
    id: 'app.common.socialCare',
    defaultMessage: 'Social care',
  },
  homeCare: {
    id: 'app.common.homeCare',
    defaultMessage: 'Home care',
  },
  healthCare: {
    id: 'app.common.healthCare',
    defaultMessage: 'Health care',
  },
  noHilmo: {
    id: 'app.common.noHilmo',
    defaultMessage: 'No hilmo',
  },
  copy: {
    id: 'app.common.copy',
    defaultMessage: 'Copy',
  },
  submit: {
    id: 'app.common.submit',
    defaultMessage: 'Submit',
  },
  results: {
    id: 'app.common.results',
    defaultMessage: 'Results',
  },
  noResults: {
    id: 'app.common.noResults',
    defaultMessage: 'No results',
  },
  next: {
    id: 'app.common.next',
    defaultMessage: 'Next',
  },
  publish: {
    id: 'app.common.publish',
    defaultMessage: 'Publish',
  },
  bookingCalendar: {
    id: 'app.common.bookingCalendar',
    defaultMessage: 'Booking calendar',
  },
  problem: {
    id: 'app.common.problem',
    defaultMessage: 'Problem',
  },
  reset: {
    id: 'app.common.reset',
    defaultMessage: 'Reset',
  },
  or: {
    id: 'app.common.or',
    defaultMessage: 'Or',
  },
  calculate: {
    id: 'app.common.calculate',
    defaultMessage: 'Calculate',
  },
  to: {
    id: 'app.common.to',
    defaultMessage: 'To',
  },
  message: {
    id: 'app.common.message',
    defaultMessage: 'Message',
  },
  error: {
    id: 'app.common.error',
    defaultMessage: 'error',
  },
  history: {
    id: 'app.components.Customers.Settings.history',
    defaultMessage: 'History',
  },
  loading: {
    id: 'app.common.loading',
    defaultMessage: 'Loading',
  },
  noNotes: {
    id: 'app.common.noNotes',
    defaultMessage: 'No notes',
  },
  showOnMap: {
    id: 'app.common.showOnMap',
    defaultMessage: 'Show on map',
  },
  discard: {
    id: 'app.common.discard',
    defaultMessage: 'Discard',
  },
  map: {
    id: 'app.common.map',
    defaultMessage: 'Map',
  },
  generatedBy: {
    id: 'app.common.generatedBy',
    defaultMessage: 'Generated by',
  },
  size: {
    id: 'app.common.size',
    defaultMessage: 'Size',
  },
  selectedDocuments: {
    id: 'app.common.selectedDocuments',
    defaultMessage: 'Selected documents',
  },
  document: {
    id: 'app.common.document',
    defaultMessage: 'Document',
  },
  documents: {
    id: 'app.common.documents',
    defaultMessage: 'Documents',
  },
  ready: {
    id: 'app.common.ready',
    defaultMessage: 'Ready',
  },
  pending: {
    id: 'app.common.pending',
    defaultMessage: 'Pending',
  },
  failed: {
    id: 'app.common.failed',
    defaultMessage: 'Failed',
  },
  repudiated: {
    id: 'app.common.repudiated',
    defaultMessage: 'Repudiated',
  },
  monday: {
    id: 'app.common.monday',
    defaultMessage: 'monday',
  },
  tuesday: {
    id: 'app.common.tuesday',
    defaultMessage: 'Tuesday',
  },
  wednesday: {
    id: 'app.common.wednesday',
    defaultMessage: 'Wednesday',
  },
  thursday: {
    id: 'app.common.thursday',
    defaultMessage: 'Thursday',
  },
  friday: {
    id: 'app.common.friday',
    defaultMessage: 'Friday',
  },
  saturday: {
    id: 'app.common.saturday',
    defaultMessage: 'Saturday',
  },
  sunday: {
    id: 'app.common.sunday',
    defaultMessage: 'Sunday',
  },
  availability: {
    id: 'app.common.availability',
    defaultMessage: 'Availability',
  },
  templates: {
    id: 'app.common.templates',
    defaultMessage: 'Templates',
  },
  views: {
    id: 'app.common.views',
    defaultMessage: 'Views',
  },
  legends: {
    id: 'app.common.legends',
    defaultMessage: 'Legends',
  },
  reason: {
    id: 'app.common.reason',
    defaultMessage: 'Reason',
  },
  singleTask: {
    id: 'app.common.singleTask',
    defaultMessage: 'Single task',
  },
  repeatedTasks: {
    id: 'app.common.repeatedTasks',
    defaultMessage: 'Repeated tasks',
  },
  continue: {
    id: 'app.common.continue',
    defaultMessage: 'Continue',
  },
  send: {
    id: 'app.common.send',
    defaultMessage: 'Send',
  },
  preview: {
    id: 'app.common.preview',
    defaultMessage: 'Preview',
  },
  settings: {
    id: 'app.common.settings',
    defaultMessage: 'Settings',
  },
  dateAndTime: {
    id: 'app.common.dateAndTime',
    defaultMessage: 'Date and time',
  },
  source: {
    id: 'app.common.source',
    defaultMessage: 'Source',
  },
  noSelection: {
    id: 'app.common.noSelection',
    defaultMessage: 'No selection',
  },
  deposit: {
    id: 'app.common.deposit',
    defaultMessage: 'Deposit',
  },
  withdraw: {
    id: 'app.common.withdraw',
    defaultMessage: 'Withdraw',
  },
  freeText: {
    id: 'app.common.freeText',
    defaultMessage: 'Free text',
  },
  editReport: {
    id: 'app.common.editReport',
    defaultMessage: 'Edit report',
  },
  addReport: {
    id: 'app.common.addReport',
    defaultMessage: 'Add Report',
  },
  addServices: {
    id: 'app.common.addServices',
    defaultMessage: 'Add Service',
  },
  customers: {
    id: 'app.common.customers',
    defaultMessage: 'Customers',
  },
  services: {
    id: 'app.common.services',
    defaultMessage: 'Services',
  },
  other: {
    id: 'app.common.other',
    defaultMessage: 'Other',
  },
  total: {
    id: 'app.common.total',
    defaultMessage: 'Total',
  },
  quantity: {
    id: 'app.common.quantity',
    defaultMessage: 'Quantity',
  },
  undo: {
    id: 'app.common.undo',
    defaultMessage: 'Undo',
  },
  showMore: {
    id: 'app.common.showMore',
    defaultMessage: 'Show more',
  },
  archive: {
    id: 'app.common.archive',
    defaultMessage: 'Archive',
  },
  taskId: {
    id: 'app.common.taskId',
    defaultMessage: 'Task ID',
  },
  updatableInfo: {
    id: 'app.common.updatableInfo',
    defaultMessage: 'This info can be updated',
  },
  previous: {
    id: 'app.common.previous',
    defaultMessage: 'Previous',
  },
  sec: {
    id: 'app.common.sec',
    defaultMessage: 'sec',
  },
  min: {
    id: 'app.common.min',
    defaultMessage: 'min',
  },
  nextTask: {
    id: 'app.common.nextTask',
    defaultMessage: 'Next task',
  },
  previousTask: {
    id: 'app.common.previousTask',
    defaultMessage: 'Previous task',
  },
  more: {
    id: 'app.common.more',
    defaultMessage: 'more',
  },
  price: {
    id: 'app.common.price',
    defaultMessage: 'Price',
  },
  amount: {
    id: 'app.common.amount',
    defaultMessage: 'Amount',
  },
  timestamp: {
    id: 'app.common.timestamp',
    defaultMessage: 'Timestamp',
  },
  current: {
    id: 'app.common.current',
    defaultMessage: 'Current',
  },
  booking: {
    id: 'app.common.booking',
    defaultMessage: 'Booking',
  },
  addBilling: {
    id: 'app.common.addBilling',
    defaultMessage: 'Add Billing',
  },
  taskAuction: {
    id: 'app.common.taskAuction',
    defaultMessage: 'Task Auction',
  },
  cashRegister: {
    id: 'app.common.cashRegister',
    defaultMessage: 'Cash Register',
  },
  servicePacket: {
    id: 'app.common.servicePacket',
    defaultMessage: 'Service Packet',
  },
  taskType: {
    id: 'app.common.taskType',
    defaultMessage: 'Task type',
  },
  taskTime: {
    id: 'app.common.taskTime',
    defaultMessage: 'Task time',
  },
  creationTime: {
    id: 'app.common.creationTime',
    defaultMessage: 'Created on',
  },
  creator: {
    id: 'app.common.creator',
    defaultMessage: 'Creator',
  },
  editingTime: {
    id: 'app.common.editingTime',
    defaultMessage: 'Editing time',
  },
  taskModified: {
    id: 'app.common.taskModified',
    defaultMessage: 'Task modified',
  },
  taskCreated: {
    id: 'app.common.taskCreated',
    defaultMessage: 'Task created',
  },
  editor: {
    id: 'app.common.editor',
    defaultMessage: 'Editor',
  },
  mobileId: {
    id: 'app.common.mobileId',
    defaultMessage: 'MobileID',
  },
  timing: {
    id: 'app.common.timing',
    defaultMessage: 'Timing',
  },
  startingTime: {
    id: 'app.common.startingTime',
    defaultMessage: 'Starting time',
  },
  endingTime: {
    id: 'app.common.endingTime',
    defaultMessage: 'Ending time',
  },
  startingLocation: {
    id: 'app.common.startingLocation',
    defaultMessage: 'Starting location',
  },
  endingLocation: {
    id: 'app.common.endingLocation',
    defaultMessage: 'Ending location',
  },
  info: {
    id: 'app.common.info',
    defaultMessage: 'Info',
  },
  taskInfo: {
    id: 'app.common.taskInfo',
    defaultMessage: 'Task info',
  },
  timeRange: {
    id: 'app.common.timeRange',
    defaultMessage: 'Time range',
  },
  deviation: {
    id: 'app.common.deviation',
    defaultMessage: 'Deviation',
  },
  plannedHours: {
    id: 'app.common.plannedHours',
    defaultMessage: 'Planned hours',
  },
  realizedHours: {
    id: 'app.common.realizedHours',
    defaultMessage: 'Realized hours',
  },
  costCenter: {
    id: 'app.common.costCenter',
    defaultMessage: 'Cost center',
  },
  summary: {
    id: 'app.common.summary',
    defaultMessage: 'Summary',
  },
  definition: {
    id: 'app.common.definition',
    defaultMessage: 'Definition',
  },
  approved: {
    id: 'app.common.approved',
    defaultMessage: 'Approved',
  },
  unapproved: {
    id: 'app.common.unapproved',
    defaultMessage: 'Unapproved',
  },
  exported: {
    id: 'app.common.exported',
    defaultMessage: 'Exported',
  },
  employeeAgreements: {
    id: 'app.common.employeeAgreements',
    defaultMessage: 'Employee agreements',
  },
  confirmApprove: {
    id: 'app.common.confirmApprove',
    defaultMessage: 'Confirm approval',
  },
  export: {
    id: 'app.common.export',
    defaultMessage: 'Export',
  },
  empty: {
    id: 'app.common.empty',
    defaultMessage: 'Empty',
  },
  task_s: {
    id: 'app.common.task_s',
    defaultMessage: "Tasks's",
  },
  workshift_s: {
    id: 'app.common.workshift_s',
    defaultMessage: "Workshift's",
  },
  hourly: {
    id: 'app.common.hourly',
    defaultMessage: 'Hourly',
  },
  monthly: {
    id: 'app.common.monthly',
    defaultMessage: 'Monthly',
  },
  deletedSuccessfully: {
    id: 'app.common.deletedSuccessfully',
    defaultMessage: 'Deleted successfully',
  },
  createdSuccessfully: {
    id: 'app.common.createdSuccessfully',
    defaultMessage: 'Created successfully',
  },
  updatedSuccessfully: {
    id: 'app.common.updatedSuccessfully',
    defaultMessage: 'Updated successfully',
  },
  comment: {
    id: 'app.common.comment',
    defaultMessage: 'Comment',
  },
  noComment: {
    id: 'app.common.noComment',
    defaultMessage: 'There is no comment',
  },
  revert: {
    id: 'app.common.revert',
    defaultMessage: 'Revert',
  },
  plannedStartTime: {
    id: 'app.common.plannedStartTime',
    defaultMessage: 'Planned start time',
  },
  plannedStartDate: {
    id: 'app.common.plannedStartDate',
    defaultMessage: 'Planned start date',
  },
  realizedStartTime: {
    id: 'app.common.realizedStartTime',
    defaultMessage: 'Realized start time',
  },
  realizedStartDate: {
    id: 'app.common.realizedStartDate',
    defaultMessage: 'Realized start date',
  },
  realizedEndTime: {
    id: 'app.common.realizedEndTime',
    defaultMessage: 'Realized end time',
  },
  realizedEndDate: {
    id: 'app.common.realizedEndDate',
    defaultMessage: 'Realized end date',
  },
  plannedEndTime: {
    id: 'app.common.plannedEndTime',
    defaultMessage: 'Planned end time',
  },
  plannedEndDate: {
    id: 'app.common.plannedEndDate',
    defaultMessage: 'Planned end date',
  },
  earliestStart: {
    id: 'app.common.earliestStartTime',
    defaultMessage: 'Earliest start time',
  },
  latestStart: {
    id: 'app.common.latestStartTime',
    defaultMessage: 'Latest start time',
  },
  customerIsAbsent: {
    id: 'app.common.customerIsAbsent',
    defaultMessage: 'Customer is absent',
  },
  plannedTimeRange: {
    id: 'app.common.plannedTimeRange',
    defaultMessage: 'Planned time range',
  },
  realisedTimeRange: {
    id: 'app.common.realizedTimeRange',
    defaultMessage: 'Realised time range',
  },
  approve: {
    id: 'app.common.approve',
    defaultMessage: 'Approve',
  },
  bookingCalendars: {
    id: 'app.common.bookingCalendars',
    defaultMessage: 'Booking calendars',
  },
  noCalendars: {
    id: 'app.common.noCalendars',
    defaultMessage: 'No calendars available',
  },
  workPlace: {
    id: 'app.common.workPlace',
    defaultMessage: 'Work place',
  },
  profitCenter: {
    id: 'app.common.profitCenter',
    defaultMessage: 'Profit center',
  },
  workShiftCode: {
    id: 'app.common.workShiftCode',
    defaultMessage: 'Work shift code',
  },
  workHours: {
    id: 'app.common.workHours',
    defaultMessage: 'Work hours',
  },
  over: {
    id: 'app.common.over',
    defaultMessage: 'Over',
  },
  under: {
    id: 'app.common.under',
    defaultMessage: 'Under',
  },
  period: {
    id: 'app.common.period',
    defaultMessage: 'Period',
  },
  notesAreUpdated: {
    id: 'app.common.notesAreUpdated',
    defaultMessage: 'Notes are updated',
  },
  distance: {
    id: 'app.common.distance',
    defaultMessage: 'Distance',
  },
  filename: {
    id: 'app.common.filename',
    defaultMessage: 'File name',
  },
  user: {
    id: 'app.common.user',
    defaultMessage: 'User',
  },
  users: {
    id: 'app.common.users',
    defaultMessage: 'Users',
  },
  payGrade: {
    id: 'app.common.payGrade',
    defaultMessage: 'Paygrade',
  },
  added: {
    id: 'app.common.added',
    defaultMessage: 'Added',
  },
  edited: {
    id: 'app.common.edited',
    defaultMessage: 'Edited',
  },
  deleted: {
    id: 'app.common.deleted',
    defaultMessage: 'Deleted',
  },
  work: {
    id: 'app.common.work',
    defaultMessage: 'Work',
  },
  employeeOffers: {
    id: 'app.common.employeeOffers',
    defaultMessage: 'Employee offers',
  },
  needsAndOffers: {
    id: 'app.common.needsAndOffers',
    defaultMessage: 'Needs and offers',
  },
  employeeIsRemoved: {
    id: 'app.common.employeeIsRemoved',
    defaultMessage: 'Employee is removed',
  },
  earlierVisits: {
    id: 'app.common.earlierVisits',
    defaultMessage: 'Earlier visits',
  },
  prefill: {
    id: 'app.common.prefilling',
    defaultMessage: 'Prefilling',
  },
  alphabeticalOrder: {
    id: 'app.common.alphabeticalOrder',
    defaultMessage: 'Alphabetical order',
  },
  ongoingRestrictionEvents: {
    id: 'app.common.ongoingRestrictionEvents',
    defaultMessage: 'Ongoing restriction events',
  },
  categories: {
    id: 'app.common.categories',
    defaultMessage: 'Categories',
  },
  dateRange: {
    id: 'app.common.dateRange',
    defaultMessage: 'Date range',
  },
  workShiftsManagement: {
    id: 'app.common.workShiftsManagement',
    defaultMessage: 'Work shifts management',
  },
  planningLists: {
    id: 'app.common.planningLists',
    defaultMessage: 'Planning lists',
  },
  weeklySummary: {
    id: 'app.common.weeklySummary',
    defaultMessage: 'Weekly summary',
  },
  startDateTimeAfterEnd: {
    id: 'app.common.startDateTimeAfterEnd',
    defaultMessage: 'Start date time cannot be after end date time',
  },
  modificationTime: {
    id: 'app.common.modificationTime',
    defaultMessage: 'Last edited',
  },
  saved: {
    id: 'app.common.saved',
    defaultMessage: 'Saved',
  },
  lastEdited: {
    id: 'app.common.lastEdited',
    defaultMessage: 'Last edited',
  },
  editable: {
    id: 'app.common.editable',
    defaultMessage: 'Editable',
  },
  absence: {
    id: 'app.common.absence',
    defaultMessage: 'Absence',
  },
  standBy: {
    id: 'app.common.standBy',
    defaultMessage: 'Standby',
  },
  customerShips: {
    id: 'app.common.customerShips',
    defaultMessage: 'Customerships', // FI: Asiakkuudet
  },
  transfers: {
    id: 'app.common.transfers',
    defaultMessage: 'Transfers', // FI: Siirrot
  },
  socialArchive: {
    id: 'app.common.socialArchive',
    defaultMessage: 'Social archive', // FI: Sosiaaliarkisto
  },
  deleteSingleEvent: {
    id: 'app.common.deleteSingleEvent',
    defaultMessage: 'Delete single event',
  },
  deleteRepeatedEvents: {
    id: 'app.common.deleteRepeatedEvents',
    defaultMessage: 'Delete repeated events',
  },
  howToProceed: {
    id: 'app.common.howToProceed',
    defaultMessage: 'How do you like to proceed?',
  },
  addWorkShift: {
    id: 'app.common.addWorkShift',
    defaultMessage: 'Add workShift',
  },
  workShiftAvailabilities: {
    id: 'app.common.workShiftAvailabilities',
    defaultMessage: 'Availabilities',
  },
  workShifts: {
    id: 'app.common.workShifts',
    defaultMessage: 'Work shifts',
  },
  additionalDetails: {
    id: 'app.common.additionalDetails',
    defaultMessage: 'Additional details',
  },
  updateSingleEvent: {
    id: 'app.common.updateSingleEvent',
    defaultMessage: 'Update single event',
  },
  updateRepeatedEvent: {
    id: 'app.common.updateRepeatedEvent',
    defaultMessage: 'Update repeated event',
  },
  shownRange: {
    id: 'app.common.shownRange',
    defaultMessage: 'Shown range',
  },
  resetTime: {
    id: 'app.common.ResetTime',
    defaultMessage: 'Reset to default range',
  },
  backToBookingView: {
    id: 'app.common.backToBookingView',
    defaultMessage: 'Back to booking view',
  },
  secondaryUnits: {
    id: 'app.common.secondaryUnits',
    defaultMessage: 'Secondary units',
  },
  reasonForDeletion: {
    id: 'app.common.reasonForDeletion',
    defaultMessage: 'Reason for deletion',
  },
  userOffers: {
    id: 'app.common.userOffers',
    defaultMessage: 'User offers',
  },
  noOffers: {
    id: 'app.common.noOffers',
    defaultMessage: 'No offers',
  },
  initializing: {
    id: 'app.common.initializing',
    defaultMessage: 'Initializing SSO...',
  },
  loggingIn: {
    id: 'app.common.loggingIn',
    defaultMessage: 'Logging in...',
  },
  redirecting: {
    id: 'app.common.redirecting',
    defaultMessage: 'Redirecting...',
  },
  noComma: {
    id: 'app.common.noComma',
    defaultMessage: 'Use "." instead of ","',
  },
  deletedBy: {
    id: 'app.common.deletedBy',
    defaultMessage: 'Deleted by',
  },
  deletedAt: {
    id: 'app.common.deletedAt',
    defaultMessage: 'Deleted at',
  },
  value: {
    id: 'app.common.value',
    defaultMessage: 'Value',
  },
  employmentAgreement: {
    id: 'app.common.employmentAgreement',
    defaultMessage: 'Employment agreement',
  },
  employmentAgreementsManagement: {
    id: 'app.common.employmentAgreementsManagement',
    defaultMessage: 'Employment agreements management',
  },
  active: {
    id: 'app.common.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'app.common.inactive',
    defaultMessage: 'Inactive',
  },
  selectCustomers: {
    id: 'app.common.selectCustomers',
    defaultMessage: 'Select customers',
  },
  changePassword: {
    id: 'app.common.changePassword',
    defaultMessage: 'Change password',
  },
  userPreferences: {
    id: 'app.common.userPreferences',
    defaultMessage: 'User preferences',
  },
  userInfo: {
    id: 'app.common.userInfo',
    defaultMessage: 'User info',
  },
  routeOptimization: {
    id: 'app.common.routeOptimization',
    defaultMessage: 'Route optimization',
  },
  frontPageWidgets: {
    id: 'app.common.frontPageWidgets',
    defaultMessage: 'Front page widgets',
  },
  customerWidgets: {
    id: 'app.common.customerWidgets',
    defaultMessage: 'Customer widgets',
  },
  selectedCustomers: {
    id: 'app.common.selectedCustomers',
    defaultMessage: 'Selected customers',
  },
  measurements: {
    id: 'app.common.measurements',
    defaultMessage: 'Measurements',
  },
  routeOptimizationUndo: {
    id: 'app.common.routeOptimizationUndo',
    defaultMessage: 'Route optimization undo',
  },
  phrase: {
    id: 'app.common.phrase',
    defaultMessage: 'Phrase',
  },
  registerSearch: {
    id: 'app.common.registerSearch',
    defaultMessage: 'Register search',
  },
  goals: {
    id: 'app.common.goals',
    defaultMessage: 'Goals',
  },
  category: {
    id: 'app.common.category',
    defaultMessage: 'Category',
  },
  selectEmployee: {
    id: 'app.common.selectEmployee',
    defaultMessage: 'Select employee',
  },
  goal: {
    id: 'app.common.goal',
    defaultMessage: 'Goal',
  },
  showWorkShifts: {
    id: 'app.common.showWorkShifts',
    defaultMessage: 'Show work shifts',
  },
  showTasks: {
    id: 'app.common.showTasks',
    defaultMessage: 'Show tasks',
  },
  showBoth: {
    id: 'app.common.showBoth',
    defaultMessage: 'Show both',
  },
  plannedBreakDuration: {
    id: 'app.common.plannedBreakDuration',
    defaultMessage: 'Planned break duration',
  },
  realizedBreakDuration: {
    id: 'app.common.realizedBreakDuration',
    defaultMessage: 'Realized break duration',
  },
  externalId: {
    id: 'app.common.externalId',
    defaultMessage: 'External ID',
  },
  findEmployee: {
    id: 'app.common.findEmployee',
    defaultMessage: 'Find employee',
  },
  waitingForReply: {
    id: 'app.common.waitingForReply',
    defaultMessage: 'Waiting for reply',
  },
  hasAccepted: {
    id: 'app.common.hasAccepted',
    defaultMessage: 'Has accepted',
  },
  hasRejected: {
    id: 'app.common.hasRejected',
    defaultMessage: 'Has rejected',
  },
  rejected: {
    id: 'app.common.rejected',
    defaultMessage: 'Rejected',
  },
  accepted: {
    id: 'app.common.accepted',
    defaultMessage: 'Accepted',
  },
  employeeInfo: {
    id: 'app.common.employeeInfo',
    defaultMessage: 'Employee info',
  },
  downloadConfirmation: {
    id: 'app.common.downloadConfirmation',
    defaultMessage: 'Are you sure you want to download the file?',
  },
  showRealizedTimes: {
    id: 'app.common.showRealizedTimes',
    defaultMessage: 'Show realized time and date',
  },
  commentCreated: {
    id: 'app.common.commentCreated',
    defaultMessage: 'Comment is created',
  },
  commentDeleted: {
    id: 'app.common.commentDeleted',
    defaultMessage: 'Comment is deleted',
  },
  commentUpdated: {
    id: 'app.common.commentUpdated',
    defaultMessage: 'Comment is updated',
  },
  result: {
    id: 'app.common.result',
    defaultMessage: 'Result',
  },
  writer: {
    id: 'app.common.writer',
    defaultMessage: 'Writer',
  },
  deleteSelected: {
    id: 'app.common.deleteSelected',
    defaultMessage: 'Delete selected',
  },
  endDateCannotBeBeforeStartDate: {
    id: 'app.common.endDateCannotBeBeforeStartDate',
    defaultMessage: 'End date cannot be before start date',
  },
  plannedBreak: {
    id: 'app.common.plannedBreak',
    defaultMessage: 'Planned break',
  },
  realizedBreak: {
    id: 'app.common.realizedBreak',
    defaultMessage: 'Realized break',
  },
  view: {
    id: 'app.common.view',
    defaultMessage: 'View',
  },
  reports: {
    id: 'app.common.reports',
    defaultMessage: 'Reports',
  },
  showRemovedUsers: {
    id: 'app.common.showRemovedUsers',
    defaultMessage: 'Show removed users',
  },
  breakShouldBeWithinWorkShiftTime: {
    id: 'app.common.breakShouldBeWithinWorkShiftTime',
    defaultMessage: 'Break time should be within work shift time',
  },
  didNotTakeBreak: {
    id: 'app.common.didNotTakeBreak',
    defaultMessage: 'Did not take a break',
  },
  thisSectionIsRequired: {
    id: 'app.common.thisSectionIsRequired',
    defaultMessage: 'This section is required',
  },
  startDateSameAsEndDate: {
    id: 'app.common.startDateSameAsEndDate',
    defaultMessage: 'Start date cannot be same as end date',
  },
  overlappingDate: {
    id: 'app.common.overlappingDate',
    defaultMessage: 'Date is overlapping with dates in another agreement',
  },
  agreementHistory: {
    id: 'app.common.agreementHistory',
    defaultMessage: 'Agreement history',
  },
  gapBetweenAgreements: {
    id: 'app.common.gapBetweenAgreements',
    defaultMessage: 'There should not be any gap between agreements',
  },
  fte: {
    id: 'app.common.fte',
    defaultMessage: 'Full time equivalence',
  },
  unknown: {
    id: 'app.common.unknown',
    defaultMessage: 'Unknown',
  },
  specialContent: {
    id: 'app.common.specialContent',
    defaultMessage: 'Special content',
  },
  eArkisto: {
    id: 'app.common.eArkisto',
    defaultMessage: 'eArkisto',
  },
  limitExceeded: {
    id: 'app.common.limitExceeded',
    defaultMessage: 'The value exceeds the minimum and maximum limits.',
  },
  replaced: {
    id: 'app.common.replaced',
    defaultMessage: 'Replaced',
  },
  deactivate: {
    id: 'app.common.deactivate',
    defaultMessage: 'Deactivate',
  },
  notice: {
    id: 'app.common.notice',
    defaultMessage: 'Notice',
  },
  addNewAgreement: {
    id: 'app.common.addNewAgreement',
    defaultMessage: 'Add new agreement',
  },
  payrollClass: {
    id: 'app.common.payrollClass',
    defaultMessage: 'Payroll class',
  },
  customerNeeds: {
    id: 'app.common.customerNeed',
    defaultMessage: 'Customer needs',
  },
  taskNeeds: {
    id: 'app.common.taskNeeds',
    defaultMessage: 'Task needs',
  },
  antiNeed: {
    id: 'app.common.antiNeed',
    defaultMessage: 'Anti-need',
  },
  need: {
    id: 'app.common.need',
    defaultMessage: 'Need',
  },
  offer: {
    id: 'app.common.offer',
    defaultMessage: 'Offer',
  },
  restrictionEvents: {
    id: 'app.common.restrictionEvents',
    defaultMessage: 'Restriction events',
  },
  noRestrictionEvents: {
    id: 'app.common.noRestrictionEvents',
    defaultMessage: 'No restriction events',
  },
  newAttachment: {
    id: 'app.common.newAttachment',
    defaultMessage: 'New attachment',
  },
  sendMessage: {
    id: 'app.common.sendMessage',
    defaultMessage: 'Send message',
  },
  version: {
    id: 'app.common.version',
    defaultMessage: 'Version',
  },
  interactionLog: {
    id: 'app.common.interactionLog',
    defaultMessage: 'Interaction log',
  },
  columns: {
    id: 'app.common.columns',
    defaultMessage: 'Columns',
  },
  showSelectedColumnsWarning: {
    id: 'app.common.showSelectedColumnsWarning',
    defaultMessage: 'Two columns at least should be selected',
  },
  customerOfAnotherUnit: {
    id: 'app.common.customerOfAnotherUnit',
    defaultMessage: 'Customer of another unit',
  },
  moveMultipleWorkShifts: {
    id: 'app.common.moveMultipleWorkShifts',
    defaultMessage: 'Move multiple work shifts',
  },
  movingWorkShiftsFromTo: {
    id: 'app.common.movingWorkShiftsFromTo',
    defaultMessage: 'Moving wok shifts from {from} to {to}',
  },
  open: {
    id: 'app.common.open',
    defaultMessage: 'Open',
  },
  ended: {
    id: 'app.common.ended',
    defaultMessage: 'Ended',
  },
  informationCannotBeOpened: {
    id: 'app.common.informationCannotBeOpened',
    defaultMessage: 'Information cannot be opened',
  },
});
